<script lang="ts" setup>
import type { Entry } from "contentful";
import {
  isTypeArticle,
  isTypeDownload,
  type TypeArticleSkeleton,
  type TypeDownloadSkeleton,
} from "~/types/contentful";
import { hasImage } from "~/lib/article/type-guards.js";
import { formatDate } from "~/lib/date-time";

const props = defineProps<{
  item: Entry<
    TypeArticleSkeleton | TypeDownloadSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >;
}>();

const type = computed(() => {
  if (isTypeArticle(props.item)) {
    return props.item.fields.articleType?.fields.title;
  }

  if (isTypeDownload(props.item)) {
    return props.item.fields.downloadType?.fields.title;
  }

  return undefined;
});

const date = computed(() => {
  if (isTypeDownload(props.item)) {
    return formatDate(new Date(props.item.fields.date).toISOString());
  } else {
    return undefined;
  }
});

const itemUrl = (
  item: Entry<
    TypeDownloadSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >,
) => (isTypeArticle(item) ? getUrl(item) : "");

const itemImage = computed(() =>
  hasImage(props.item)
    ? props.item.fields.image
    : isTypeDownload(props.item) && props.item.fields.downloadType?.fields.image
      ? props.item.fields.downloadType.fields.image
      : "",
);
</script>

<template>
  <section
    class="w-full rounded-xl bg-navy p-6 text-white lg:p-8"
  >
    <div class="grid grid-cols-1 gap-4">
      <div class="col-span-12 flex items-center">
        <NuxtIcon
          class="mr-4 text-blue"
          name="ussif:video-camera"
        />
        <UiDate
          :date="date"
          hide-slash
        />
      </div>

      <div class="col-span-12 flex flex-col">
        <h3 class="mb-5 text-left">
          <NuxtLink :to="item.fields.file?.fields.file?.url">
            {{ item.fields.title }}
          </NuxtLink>
        </h3>

        <div class="mb-4 flex items-center">
          <UiBadge
            v-if="type"
            bg-colour="#e8e8e8"
          >
            {{ type }}
          </UiBadge>
        </div>
      </div>

      <div class="col-span-12 mt-5 flex items-center justify-between lg:mt-10">
        <NuxtLink
          v-if="item.fields.file?.fields.file?.url"
          :to="item.fields.file?.fields.file?.url"
          class="btn min-w-[140px] border-blue transition-all duration-200 hover:border-white"
        >
          View
        </NuxtLink>
        <div class="hidden items-center sm:flex">
          <NuxtIcon
            class="mr-2 text-blue"
            name="ussif:clock"
          />
          {{ item.fields.videoLength }}
        </div>
      </div>
    </div>
  </section>
</template>
