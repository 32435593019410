import type { Entry, EntrySkeletonType } from "contentful";
import type { TypeNavDropdown, TypeNavLink, TypeNavPromo, TypeNavGroup, TypeNavColumns } from "~/types/TypeNav";
import type { TypeRoutes } from "~/types/TypeRoutes";
import {
  isTypeArticle,
  isTypePage,
  type TypePage,
  type TypePageSkeleton,
  type TypeArticleSkeleton,
  type TypeNavigationMainSkeleton,
  type TypeNavigationMainDropdownSkeleton,
  type TypeNavigationMainPromoSkeleton,
  type TypeNavigationMainDropdownLinkSkeleton,
  type TypeNavigationColumnsSkeleton,
  type TypeNavigationGroupSkeleton,
  isTypeNavigationMainDropdown,
  isTypeNavigationMainDropdownLink,
  isTypeNavigationColumns,
  isTypeNavigationGroup,
} from "~/types/contentful";
import type { RouteLocationNormalizedLoaded } from "#vue-router";

export const buildUrl = (content: Entry<EntrySkeletonType, "WITHOUT_UNRESOLVABLE_LINKS">) => {
  if (isTypeArticle(content) || isTypePage(content)) {
    const slugs: string[] = [content.fields.slug !== "home" ? content.fields.slug : ""];

    let parent = content.fields.parent;

    if (parent?.fields) {
      do {
        if (parent.fields.slug !== "home") {
          slugs.push(parent.fields.slug);
        }
      } while ((parent = parent.fields.parent));
    }

    return `/${slugs.reverse().join("/")}`;
  }

  throw new Error(`Failed to build URL for ${content.sys.contentType.sys.id}`);
};

export const listRoutes = async () => {
  let items: Entry<EntrySkeletonType, "WITHOUT_UNRESOLVABLE_LINKS">[] = [];

  let results;

  do {
    results = await (
      await import("./contentful-client")
    ).default.getEntries<TypeArticleSkeleton | TypePageSkeleton>({
      // @ts-expect-error sys.contentType is actually allowed if no content_type property is provided
      "sys.contentType.sys.id[in]": "article,page",
      "skip": items.length,
      "include": 0,
      "limit": 1000,
    });

    items = items.concat(results.items);
  } while (results.total > items.length);

  const routes: TypeRoutes = [];

  const sysIdPageIndex = Object.fromEntries(
    items
      .filter((entry): entry is TypePage<"WITHOUT_UNRESOLVABLE_LINKS"> => isTypePage(entry))
      .map((entry) => [entry.sys.id, entry]),
  );

  items.forEach((entry) => {
    if ((isTypeArticle(entry) || isTypePage(entry)) && entry.fields.parent) {
      entry.fields.parent = sysIdPageIndex[entry.fields.parent.sys.id];
    }
  });

  items.forEach((entry) => {
    if (isTypeArticle(entry) || isTypePage(entry)) {
      const path = buildUrl(entry);

      if (path) {
        const route = {
          title: entry.fields.title,
          sysId: [entry.sys.id],
          contentType: entry.sys.contentType.sys.id,
          lastUpdated: new Date(entry.sys.updatedAt),
        };

        routes.push({ ...route, path });
      }
    }
  });

  return routes;
};

export const getNav = async (sysId: string) => {
  let items: Entry<EntrySkeletonType, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[] = [];

  let results;

  do {
    results = await (
      await import("./contentful-client")
    ).default.getEntries<
      | TypeNavigationMainSkeleton
      | TypeNavigationMainDropdownSkeleton
      | TypeNavigationMainPromoSkeleton
      | TypeNavigationMainDropdownLinkSkeleton
      | TypeNavigationColumnsSkeleton
      | TypeNavigationGroupSkeleton
    >({
      // @ts-expect-error sys.contentType is actually allowed if no content_type property is provided
      "sys.contentType.sys.id[in]":
        "navigationMain,navigationMainDropdown,navigationMainDropdownLink,navigationMainPromo,navigationColumns,navigationGroup",
      "skip": items.length,
      "limit": 100,
    });

    items = items.concat(results.items);
  } while (results.total > items.length);

  const resolver = (
    entry: Entry<EntrySkeletonType, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">,
  ): TypeNavDropdown | TypeNavLink | TypeNavPromo | TypeNavColumns | TypeNavGroup => {
    if (isTypeArticle(entry) || isTypePage(entry)) {
      return {
        label: entry.fields.title,
        link: buildUrl(entry),
      } as TypeNavLink;
    }

    if (isTypeNavigationMainDropdown(entry)) {
      return {
        name: entry.fields.name,
        primaryLinks: entry.fields.primaryLinks?.flatMap((f) => f ?? []).map(resolver),
        promo: entry.fields.promo as TypeNavPromo | undefined,
        items: entry.fields.items?.flatMap((f) => f ?? []).map(resolver),
      } as TypeNavDropdown;
    }

    if (isTypeNavigationMainDropdownLink(entry)) {
      return {
        label: entry.fields.label,
        link: entry.fields.link ? buildUrl(entry.fields.link) : "",
        copy: entry.fields.copy ? entry.fields.copy : "",
      } as TypeNavLink;
    }

    if (isTypeNavigationColumns(entry)) {
      return {
        title: entry.fields.title,
        items: entry.fields.items?.flatMap((f) => f ?? []).map(resolver),
      } as TypeNavColumns;
    }

    if (isTypeNavigationGroup(entry)) {
      return {
        title: entry.fields.title,
        links: entry.fields.links?.flatMap((f) => f ?? []).map(resolver),
      } as TypeNavGroup;
    }

    throw new Error(`Failed to resolve nav entry ${entry.sys.id}`);
  };

  return items
    .find(
      (item): item is Entry<TypeNavigationMainSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB"> =>
        item.sys.id === sysId,
    )
    ?.fields.items.flatMap((f) => f ?? [])
    .map(resolver);
};

export const isActiveNav = (
  item: TypeNavDropdown | TypeNavLink | TypeNavPromo | TypeNavColumns | TypeNavGroup,
  route: RouteLocationNormalizedLoaded,
) => {
  if ("link" in item && item.link) {
    return route.fullPath.startsWith(item.link);
  } else if ("primaryLinks" in item) {
    return route.fullPath.startsWith(item.primaryLinks[0]?.link ?? "");
  }

  return false;
};
