<script lang="ts" setup>
import type { TypeBlockLogosCarousel } from "~/types/contentful";

const props = defineProps<{
  fields: TypeBlockLogosCarousel<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"];
}>();
const logos = props.fields.logos ?? [];
const half = Math.ceil(logos.length / 2);
const firstHalf = logos.slice(0, half);
const secondHalf = logos.slice(half);
</script>

<template>
  <UiContainer
    class="logos-carousel relative bg-white pb-24"
    :no-padding="true"
  >
    <h2
      v-if="fields.displayTitle"
      class="pt-10 text-center lg:pt-24"
    >
      {{ fields.title }}
    </h2>

    <section
      class="scroll scroll--left relative flex w-full overflow-hidden"
      style="/* stylelint-disable-line */ --time: 100s"
    >
      <div class="whitespace-nowrap">
        <span
          v-for="image in firstHalf"
          :key="image?.fields.title"
          class="m-6 inline-flex h-[90px] w-[180px] items-center justify-center bg-white"
        >
          <NuxtImg
            class="size-full object-contain"
            :src="image?.fields.file?.url"
            :alt="'logo - ' + image?.fields.title"
            width="180"
            loading="lazy"
          />
        </span>
      </div>
      <div class="whitespace-nowrap">
        <span
          v-for="image in firstHalf"
          :key="image?.fields.title"
          class="m-6 inline-flex h-[90px] w-[180px] items-center justify-center bg-white"
        >
          <img
            :src="image?.fields.file?.url"
            :alt="'logo - ' + image?.fields.title"
            class="size-full object-contain"
          />
        </span>
      </div>
    </section>

    <section
      class="scroll scroll--right relative flex w-full overflow-hidden"
      style="/* stylelint-disable-line */ --time: 100s"
    >
      <div class="whitespace-nowrap">
        <span
          v-for="image in secondHalf"
          :key="image?.fields.title"
          class="m-6 inline-flex h-[90px] w-[180px] items-center justify-center bg-white"
        >
          <NuxtImg
            class="size-full object-contain"
            :src="image?.fields.file?.url"
            :alt="'logo - ' + image?.fields.title"
            width="180"
            loading="lazy"
          />
        </span>
      </div>
      <div class="whitespace-nowrap">
        <span
          v-for="image in secondHalf"
          :key="image?.fields.title"
          class="m-6 inline-flex h-[90px] w-[180px] items-center justify-center bg-white"
        >
          <img
            :src="image?.fields.file?.url"
            :alt="'logo - ' + image?.fields.title"
            class="size-full object-contain"
          />
        </span>
      </div>
    </section>
  </UiContainer>
</template>

<style lang="scss" scoped>
.scroll {
  mask-image: linear-gradient(90deg,
      transparent,
      #fff 20%,
      #fff 80%,
      transparent);
}

.scroll--left {
  div {
    animation: scroll var(--time) linear infinite;
    animation-delay: calc(var(--time) * -1);

    &:nth-child(2) {
      animation: scroll2 var(--time) linear infinite;
      animation-delay: calc(var(--time) / -2);
    }
  }
}

.scroll--right {
  div {
    animation: scroll3 var(--time) linear infinite;
    animation-delay: calc(var(--time) * -1);

    &:nth-child(2) {
      animation: scroll4 var(--time) linear infinite;
      animation-delay: calc(var(--time) / -2);
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll2 {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-200%);
  }
}

@keyframes scroll3 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes scroll4 {
  0% {
    transform: translateX(-200%);
  }

  100% {
    transform: translateX(0%);
  }
}
</style>
