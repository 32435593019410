import type { Block, Inline } from "@contentful/rich-text-types";
// Use `yarn nuxi analyzer` to find components that should be lazy-loaded
import {
  HeaderHome,
  HeaderIntro,
  NavigationSubpage,
  NavigationColumns,
  BlockText,
  BlockDualText,
  BlockEventsCarousel,
  BlockLogosCarousel,
  BlockParallaxImage,
  BlockBannerElement,
  BlockNewsGrid,
  BlockMediaText,
  BlockMediaTextCarousel,
  BlockTextGrid,
  BlockProfileCards,
  BlockHub,
  BlockHtml,
  BlockTabbedList,
  TextHighlight,
  TextStats,
} from "#components";

const componentMap = {
  headerHome: HeaderHome,
  headerIntro: HeaderIntro,
  navigationSubpage: NavigationSubpage,
  navigationColumns: NavigationColumns,
  blockText: BlockText,
  blockDualText: BlockDualText,
  blockLogosCarousel: BlockLogosCarousel,
  blockEventsCarousel: BlockEventsCarousel,
  blockParallaxImage: BlockParallaxImage,
  blockBannerElement: BlockBannerElement,
  blockMediaText: BlockMediaText,
  blockMediaTextCarousel: BlockMediaTextCarousel,
  blockNewsGrid: BlockNewsGrid,
  blockTextGrid: BlockTextGrid,
  blockTabbedList: BlockTabbedList,
  blockProfileCards: BlockProfileCards,
  blockHtml: BlockHtml,
  cardBlockArticle: BlockHub, // will use it for all hub pages/tables (policies, reports, news, downloads)
  highlight: TextHighlight,
  stats: TextStats,
};

export default (node: Block | Inline) => {
  if (
    !node.data.target
    || ("contentType" in node.data.target.sys && !node.data.target.sys.contentType)
  ) {
    return {};
  }

  const componentKey = String(node.data.target.sys.contentType.sys.id);

  const component = ((key?: string): key is keyof typeof componentMap =>
    !!key && key in componentMap)(componentKey)
    ? componentMap[componentKey]
    : undefined;

  return {
    component: component ?? componentKey,
    props:
      component && component.name !== "AsyncComponentWrapper"
        ? Object.fromEntries(
          Object.entries(node.data.target).filter(([key]) =>
            Object.keys("props" in component ? component.props : {}).includes(
              key,
            ),
          ),
        )
        : node.data.target,
  };
};
