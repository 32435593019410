<script lang="ts" setup>
import type { EntrySys } from "contentful";

const props = defineProps<{
  sys: EntrySys;
}>();

const { data: navItems } = await useFetch("/api/nav", {
  query: { id: props.sys.id },
});
</script>

<template>
  <div
    v-for="(column, indexNavItems) in navItems"
    :key="indexNavItems"
    class="w-1/2 py-8 md:w-1/3"
  >
    <h4 class="text-xl font-bold opacity-40">
      {{ column.title }}
    </h4>
    <nav
      class="mt-6 flex flex-col gap-y-4"
    >
      <NuxtLink
        v-for="(item, indexLinks) in column.links"
        :key="indexLinks"
        :to="item.link"
      >
        {{ item.label }}
      </NuxtLink>
      <!-- temp hardcoded links to the old website -->
      <a
        v-if="indexNavItems === 0"
        href="https://www.ussif.org/join"
      >Membership Terms</a>
      <a
        v-if="indexNavItems === 0"
        href="https://www.ussif.org/contact"
      >Contact Us</a>
      <!-- temp hardcoded links to old website -->
    </nav>
  </div>
</template>
