<template>
  <form
    class="mb-8 flex justify-end md:w-1/2"
    name="subscribe-footer"
    netlify
  >
    <input
      type="hidden"
      name="form-name"
      value="subscribe"
    />
    <div class="h-fit w-full bg-white/5 p-12 sm:w-[530px]">
      <h4 class="text-4xl font-bold max-sm:leading-10 lg:text-[2.375rem]">
        Subscribe to our newsletter
      </h4>
      <p class="mb-10 mt-5 text-tablet-xl leading-snug">
        Get the latest sustainable investment news and updates from
        US SIF.
      </p>

      <div class="relative mb-2">
        <input
          id="email-subscribe"
          type="email"
          name="email"
          required
          placeholder="Enter your email"
          class="w-full rounded-md border border-white bg-transparent p-5 pr-24 text-base text-white focus:border-blue/80"
        />
        <button
          type="submit"
          class="absolute right-2 top-1/2 -translate-y-1/2 rounded-md bg-blue p-3 text-white hover:bg-blue/90"
        >
          Subscribe
        </button>
      </div>
    </div>
  </form>
</template>
